
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    team: {
      required: true
    },
    ev: {
      required: true
    },
    config: {
      required: true
    }
  }
})
export default class Team extends Vue {
  getTeamNameStyle(config: any) {
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }
    if (config.teamNameColor !== undefined && config.teamNameColor !== null) {
      obj['color'] = config.teamNameColor;
    }

    return obj;

  }

  getAvatarStyle(config: any) {
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }

    if (config.mainColor !== undefined && config.mainColor !== null) {
      obj['borderColor'] = config.mainColor;
    }

    return obj;
  }
}
