import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = {
  key: 0,
  class: "tabs-menu",
  "aria-label": "Person settings"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ev && _ctx.ev.id !== undefined)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: _ctx.eventLink(_ctx.ev.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.eventLink(_ctx.ev.id) === _ctx.actualPath()}]),
                style: _normalizeStyle(_ctx.getTabStyle(_ctx.config, _ctx.eventLink(_ctx.ev.id)))
              }, "Stats", 6)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, {
            to: _ctx.teamFeedLink(_ctx.ev.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.teamFeedLink(_ctx.ev.id) === _ctx.actualPath()}]),
                style: _normalizeStyle(_ctx.getTabStyle(_ctx.config, _ctx.teamFeedLink(_ctx.ev.id)))
              }, _toDisplayString(_ctx.ev.team.name), 7)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, {
            to: _ctx.eventFeedLink(_ctx.ev.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.eventFeedLink(_ctx.ev.id) === _ctx.actualPath()}]),
                style: _normalizeStyle(_ctx.getTabStyle(_ctx.config, _ctx.eventFeedLink(_ctx.ev.id)))
              }, _toDisplayString(_ctx.ev.tabName ? _ctx.ev.tabName : 'Fanzone'), 7)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}