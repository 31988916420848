

import Settings from "@/services/Settings";
import {Options, Vue} from "vue-class-component";
import Subtitle from "@/components/elements/Subtitle.vue";
import Header from "@/components/elements/Header.vue";
import HeroEvent from "@/components/elements/HeroEvent.vue";
import TeamTitle from "@/components/title/TeamTitle.vue";
import Stream from "@/components/elements/Stream.vue";

@Options({
  components: {
    Subtitle,
    Header,
    HeroEvent,
    TeamTitle,
    Stream
  },
})
export default class EventFeed extends Vue {
  private id: string | null = null;
  private host: string | null = null;
  private loader = false;
  private loaderFeedData = false;
  private event: any = {};
  private projectId: string | undefined;
  private page = 1;
  private totalPages = 1;
  private limit = 12;
  private total = 0;
  private feedData = [];
  private config = {};

  loadData(): void {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/public/events/' + this.id, {
      params: {}
    }).then((response) => {
      this.event = response.data;
      this.projectId = response.data.projectTeamId;
      this.config = response.data.config;
      this.loader = false;
      this.loadFeedData();
    }, () => {
      this.loader = false;
    });
  }

  loadFeedData(): void {
    if (this.loaderFeedData) return;
    this.loaderFeedData = true;
    this.$http.get(this.host + '/public/results/' + this.projectId +'?sort_by=time%20desc&page='+ this.page +'&limit=' + this.limit, {
      params: {}
    }).then((response) => {
      this.feedData = [
        ...this.feedData,
        ...response.data.inline.resources
      ] as never[];
      this.total = response.data.total;
      this.loaderFeedData = false;
      this.totalPages = response.data.pages;
    }, () => {
      this.loaderFeedData = false;
    });
  }

  paginateData(): void {
    this.page+=1;
    this.loadFeedData();
  }

  mounted(): void {
    this.id = this.$route.params.eventId as string;
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loadData();
  }
}

