
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    team: {
      required: true
    },
    ev: {
      required: true
    },
    config: {
      required: true
    }
  }
})
export default class TabsEvent extends Vue {
  static EventLink = '/event/{eventId}';
  static TeamFeedLink = '/event/feed/{eventId}';
  static EventFeedLink = '/event/fanzone/{eventId}';

  eventLink(eventId: string): string {
    return TabsEvent.EventLink.replace('{eventId}', eventId);
  }

  teamFeedLink(eventId: string): string {
    return TabsEvent.TeamFeedLink
        .replace('{eventId}', eventId)
  }

  eventFeedLink(eventId: string): string {
    return TabsEvent.EventFeedLink.replace('{eventId}', eventId);
  }

  actualPath(): string {
    return this.$route.path;
  }

  getTabStyle(config: any, path: string) {
    const isActualPath = path === this.actualPath();
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }

    if (isActualPath) {
      if (config.mainColor !== undefined && config.mainColor !== null) {
        obj['backgroundColor'] = config.mainColor;
      }
    } else {
      if (config.tabColor !== undefined && config.tabColor !== null) {
        obj['backgroundColor'] = config.tabColor;
      }
    }

    if (config.textTabColor !== undefined && config.textTabColor !== null) {
      obj['color'] = config.textTabColor;
    }

    return obj;
  }
}
