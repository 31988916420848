import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "versus-teams d-flex flex-justify-center flex-items-center my-sm-5"
}
const _hoisted_3 = {
  class: "team py-5",
  style: {"min-height":"300px"}
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.team)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.team.avatar !== null && _ctx.ev.showTeamAvatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "img-circle img-circle-bordered",
                  style: _normalizeStyle(_ctx.getAvatarStyle(_ctx.config)),
                  src: _ctx.team.avatar.url
                }, null, 12, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.ev.showTeamName)
              ? (_openBlock(), _createElementBlock("h1", {
                  key: 1,
                  class: "team-name",
                  style: _normalizeStyle(_ctx.getTeamNameStyle(_ctx.config))
                }, _toDisplayString(_ctx.team.name), 5))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}