import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-472ce7e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "hero-wrapper hero-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Team = _resolveComponent("Team")!
  const _component_TabsEvent = _resolveComponent("TabsEvent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.backgroundPhoto !== null && _ctx.backgroundPhoto !== undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "hero-wrapper hero-center",
          style: _normalizeStyle({'background-image': 'url(' + _ctx.backgroundPhoto.url + ')'})
        }, [
          _createVNode(_component_Team, {
            team: _ctx.team,
            ev: _ctx.ev,
            config: _ctx.config
          }, null, 8, ["team", "ev", "config"]),
          _createVNode(_component_TabsEvent, {
            team: _ctx.team,
            ev: _ctx.ev,
            config: _ctx.config
          }, null, 8, ["team", "ev", "config"])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.backgroundPhoto === null || _ctx.backgroundPhoto === undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Team, {
            team: _ctx.team,
            ev: _ctx.ev,
            config: _ctx.config
          }, null, 8, ["team", "ev", "config"]),
          _createVNode(_component_TabsEvent, {
            team: _ctx.team,
            ev: _ctx.ev,
            config: _ctx.config
          }, null, 8, ["team", "ev", "config"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}