
import Team from "@/components/elements/Team.vue";
import TabsEvent from "@/components/elements/TabsEvent.vue";

import {Options, Vue} from "vue-class-component";

@Options({
  components: {
    TabsEvent,
    Team
  },
  props: {
    team: {
      required: true
    },
    ev: {
      required: true
    },
    config: {
      required: true
    },
    backgroundPhoto: {
      required: true
    }
  }
})
export default class HeroEvent extends Vue {

}
